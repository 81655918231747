<template>
	<Layout>

		<v-container fluid v-if="loading">
			<v-row height="100vh" justify="center" align="center">
				<v-col cols="12" class="text-center"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></v-col>
				<v-col cols="12"><h3 class="text-center">Cargando información... </h3></v-col>
			</v-row>
		</v-container>

		<v-container v-else>
			<v-row>
				<v-col cols="12">
					<v-card class="mx-auto" max-width="500" >
						<v-img
							contain
							src="/images/certificacion.png"
						>
							<v-card-text>
								<div class="salutacion-prev text-center">
									{{ salutacion }} {{ fullName }}
								</div>
								<div class="cedula-prev">
									V-{{ formatCedula(user.cedula)  }}
								</div>
								<div class="titularidad-prev">
									{{ titularidad }}
								</div>
							</v-card-text>
						</v-img>
						<v-card-actions>
							<v-btn text color="grey" :to="{name: 'Dashboard'}"><v-icon class="mr-2">mdi-chevron-double-left</v-icon> REGRESAR</v-btn>
							<v-spacer />
							<v-btn class="primary" @click="generateReport">DESCARGAR</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        filename="certificado"
        :pdf-quality="2"
        pdf-format="letter"
        pdf-orientation="landscape"
        pdf-content-width="1000px"
				:paginate-elements-by-height="1100"

        ref="html2Pdf"
    >
        <section slot="pdf-content" class="">
					<div class="cert-background">
						<div>
							<div class="salutacion-print">
								{{ salutacion }} {{ fullName }}
							</div>
							<div class="cedula-print">
								V-{{ formatCedula(user.cedula)  }}
							</div>
							<div class="titularidad-print">
								{{ titularidad }}
							</div>
							<qr-code
								:size="100"
								:text="`https://miembros.svderma.org/validar/${user._id}`"
								color="#007EF3"
								bg-color="#fff"
								error-level="L"
							/>
						</div>
					</div>
        </section>
    </vue-html2pdf>

	</Layout>
</template>

<script>
	import VueHtml2pdf from 'vue-html2pdf'
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'Certificado',
		components: {
      VueHtml2pdf
    },
		data: ()=>({
			loading: false,
			user: {
				_id: '',
				cedula: '',
				nombre: '',
				apellido: '',
				miembro: '',
				sexo: '',
			},
		}),
		mounted(){
			this.getData()
		},
		methods: {
			generateReport () {
				this.$refs.html2Pdf.generatePdf()
			},
			async getData(){
				this.loading = true
				const id = this.$store.state.userModule.user._id
				let me = this;
				await axios.get(`user/query?_id=${id}` ,configuration)
					.then(function (response) {
						me.user._id = id,
						me.user.cedula = response.data.cedula,
						me.user.nombre = response.data.nombre,
						me.user.apellido = response.data.apellido,
						me.user.miembro = response.data.miembro,
						me.user.sexo = response.data.sexo,
						me.loading = false
					})
					.catch(function (e) {
						console.log(e.message)
					})
			},
			formatCedula(value) {
        let val = (value/1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    	}
    },
		computed: {
			salutacion(){
				return (this.user.sexo == 'Femenino') ? 'Dra.' : 'Dr.';
			},
			fullName(){
				return `${this.user.nombre} ${this.user.apellido}`
			},
			titularidad(){
				return `Miembro ${this.user.miembro}`
			}
		}


	}
</script>

<style  scoped>

	.salutacion-prev {
		margin-top: 20% !important;
		font-family: 'Times New Roman', Times, serif;
		font-size:2.5vh;
		font-weight: 800;
		color: #C8AB37;
	}
	.cedula-prev {
		margin-top: 2.5% !important;
		margin-left:56% !important;
		color: #C8AB37;
		font-weight: 600;
		font-size: 1.3vh;
	}
	.titularidad-prev {
		margin-top: 2% !important;
		float: right;
		margin-right: 48%;
		color: #C8AB37;
		font-weight: 600;
		font-size: 0.9rem;
	}
	.salutacion-print {
		width: 980px;
		text-align: center !important;
		margin-top: 220px !important;
		font-family: 'Times New Roman', Times, serif;
		font-size: 2.4rem;
		font-weight: 800;
		color: #C8AB37;
	}
	.cedula-print {
		margin-top: 12px !important;
		margin-left: 518px!important;
		color: #C8AB37;
		font-weight: 600;
		font-size: 1.4rem;
	}
	.titularidad-print {
		margin-top: 30px !important;
		float: right;
		margin-right: 482px;
		color: #C8AB37;
		font-weight: 600;
		font-size: 1.4rem;
	}

	.cert-background{
		display: flex;
		margin-top: 40px;
		margin-left: 50px;
		height: 700px;
		background-image: url("/images/certificacion.png");
		background-size:contain;
	}

	.lds-spinner {
		color: official;
		display: inline-block;
		position: relative;
		width: 180px;
		height: 180px;
	}
	.lds-spinner div {
		transform-origin: 40px 40px;
		animation: lds-spinner 1.2s linear infinite;
	}
	.lds-spinner div:after {
		content: " ";
		display: block;
		position: absolute;
		top: 3px;
		left: 37px;
		width: 6px;
		height: 18px;
		border-radius: 20%;
		background: rgb(2, 3, 71);
	}
	.lds-spinner div:nth-child(1) {
		transform: rotate(0deg);
		animation-delay: -1.1s;
	}
	.lds-spinner div:nth-child(2) {
		transform: rotate(30deg);
		animation-delay: -1s;
	}
	.lds-spinner div:nth-child(3) {
		transform: rotate(60deg);
		animation-delay: -0.9s;
	}
	.lds-spinner div:nth-child(4) {
		transform: rotate(90deg);
		animation-delay: -0.8s;
	}
	.lds-spinner div:nth-child(5) {
		transform: rotate(120deg);
		animation-delay: -0.7s;
	}
	.lds-spinner div:nth-child(6) {
		transform: rotate(150deg);
		animation-delay: -0.6s;
	}
	.lds-spinner div:nth-child(7) {
		transform: rotate(180deg);
		animation-delay: -0.5s;
	}
	.lds-spinner div:nth-child(8) {
		transform: rotate(210deg);
		animation-delay: -0.4s;
	}
	.lds-spinner div:nth-child(9) {
		transform: rotate(240deg);
		animation-delay: -0.3s;
	}
	.lds-spinner div:nth-child(10) {
		transform: rotate(270deg);
		animation-delay: -0.2s;
	}
	.lds-spinner div:nth-child(11) {
		transform: rotate(300deg);
		animation-delay: -0.1s;
	}
	.lds-spinner div:nth-child(12) {
		transform: rotate(330deg);
		animation-delay: 0s;
	}
	@keyframes lds-spinner {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}


</style>